import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import classnames from 'classnames';

export type ButtonVariant = 'primary' | 'secondary' | 'outline';

export default function Button(
  props: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { variant?: ButtonVariant },
) {
  const {
    disabled,
    className: baseClassName,
    variant: defaultVariant = 'primary',
  } = props;
  const variant = disabled ? undefined : defaultVariant;
  const className = classnames(
    [
      baseClassName,
      'h-10 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline',
    ],
    {
      'bg-primary text-white hover:opacity-70': variant === 'primary',
      'bg-secondary text-white hover:opacity-70': variant === 'secondary',
      'bg-white text-primary border-black border-2 hover:opacity-70':
        variant === 'outline',
      'bg-gray-300 text-white': disabled,
    },
  );

  return <button {...props} className={className} />;
}
