import { useQuery } from 'react-query';
import { IdName } from 'core';
import { useApi } from 'providers';

const useJobTitles = (name?: string) => {
  const api = useApi();

  return useQuery<IdName[]>(
    ['jobTitles', api, name],
    async () => {
      if (!name) return []

      const { data } = await api.classification.jobTitlesList({ name });

      return data;
    },
    { staleTime: Number.POSITIVE_INFINITY },
  );
};

export default useJobTitles;
