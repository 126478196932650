import React, { useCallback, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Account } from 'core';

import { Button, Error, Input, Label, Modal } from './';
import { ModalProps } from './modal';

export interface CreateSuperUserModalProps extends Pick<ModalProps, 'onClose'> {
  onCreate(data: Account): void;
}

export default function CreateSuperUserModal({
  onClose,
  onCreate,
}: CreateSuperUserModalProps) {
  const [checked, setChecked] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Account>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const handleToggleSuperUser = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      field: ControllerRenderProps<Account, 'is_superuser'>,
    ) => {
      e.stopPropagation();
      setChecked(!checked);
      field.onChange(!checked);
    },
    [checked],
  );

  return (
    <Modal
      closeLabel="Cancel"
      onClose={onClose}
      SubmitButton={
        <Button
          disabled={!isValid}
          onClick={handleSubmit(onCreate)}
          type="submit"
        >
          Create
        </Button>
      }
      title="Create New Admin User"
    >
      <form className="flex flex-col p-2 gap-4">
        <Label label="First name">
          <Input
            {...register('first', { required: true })}
            id="first"
            placeholder="Type first name"
          />
          {errors.first && (
            <div className="absolute -bottom-2 text-xs">
              <Error message="This field is required" />
            </div>
          )}
        </Label>
        <Label label="Last name">
          <Input
            {...register('last', { required: true })}
            id="last"
            placeholder="Type last name"
          />
          {errors.last && (
            <div className="absolute -bottom-2 text-xs">
              <Error message="This field is required" />
            </div>
          )}
        </Label>
        <Label label="Middle name">
          <Input
            {...register('middle', { required: false })}
            id="middle"
            placeholder="Middle name here..."
          />
          {errors.middle && (
            <div className="absolute -bottom-2 text-xs">
              <Error message="Invalid value" />
            </div>
          )}
        </Label>
        <Label label="Email">
          <Input
            {...register('email', { required: true })}
            id="email"
            placeholder="john.doe@mail.com"
          />
          {errors.email && (
            <div className="absolute -bottom-2 text-xs">
              <Error message="This field is required" />
            </div>
          )}
        </Label>
        <Label label="Password">
          <Input
            {...register('password', { required: true })}
            id="password"
            placeholder="Password"
          />
          {errors.password && (
            <div className="absolute -bottom-2 text-xs">
              <Error message="This field is required" />
            </div>
          )}
        </Label>
        <Controller
          name="is_superuser"
          control={control}
          defaultValue={false}
          rules={{ required: false }}
          render={({ field }) => {
            return (
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={e => handleToggleSuperUser(e, field)}
              >
                <input type="checkbox" checked={checked} />
                <span>Is Super User?</span>
              </div>
            );
          }}
        />
      </form>
    </Modal>
  );
}
