import { useMutation } from 'react-query';
import { useApi } from 'providers';

interface MutationParams {
  email: string;
  enabled: boolean;
}

const useToggleSuperUser = () => {
  const api = useApi();

  return useMutation(({ email, enabled }: MutationParams) => {
    const action: keyof typeof api.superUsers = enabled
      ? 'enableCreate'
      : 'disableCreate';

    return api.superUsers[action]({ email });
  });
};

export default useToggleSuperUser;
