import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ErrorContext, handleError } from 'core';
import { useForgotPassword } from 'hooks';
import { toast } from 'react-toastify';
import { Input, Button, Error, Loader } from 'components';
import withPublicLayout from 'layouts/with-public-layout';

import { ROUTES } from '../constants';

type ForgotPassworData = {
  email: string;
};

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPassworData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { mutateAsync } = useForgotPassword();

  const Msg = () => (
    <div>
      <p>Please, check your email for verification code.</p>
      <p>
        Then go and{' '}
        <Link
          to={ROUTES.RESET_PASSWORD}
          className="text-secondary font-semibold cursor-pointer"
        >
          Set new password
        </Link>
      </p>
    </div>
  );

  const onSubmit = async (data: ForgotPassworData) => {
    try {
      await mutateAsync(data.email);
      toast(<Msg />, { autoClose: false });
    } catch (error) {
      handleError(error, ErrorContext.Authorization);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="flex justify-center">
      <form className="w-72 m-5 py-5 flex flex-col gap-5 ">
        <p className="font-bold text-primary text-xl text-center">
          Forgot password
        </p>
        <Input
          {...register('email', { required: true })}
          id="email"
          className="rounded shadow p-2"
          placeholder="Email"
          autoComplete="email"
        />
        {errors.email && <Error message={'Email is required'} />}
        <div className="flex flex-row justify-between items-baseline">
          <Link
            to={ROUTES.LOGIN}
            className="text-secondary font-semibold cursor-pointer"
          >
            Back to Log in
          </Link>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};
export default withPublicLayout(ForgotPassword);
