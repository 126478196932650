type ErrorProps = {
  message?: string;
};
const Error = ({ message }: ErrorProps) => {
  return (
    <div className="flex justify-items-start text-red-600">
      {message ? message : 'Oops. Something went wrong.'}
    </div>
  );
};

export default Error;
