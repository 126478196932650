import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import { useApi } from 'providers';
import { AUTH, ROUTES } from '../constants';

const Logout = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  api.setSecurityData(null);
  queryClient.clear();
  localStorage.removeItem(AUTH.ID_TOKEN);
  return <Redirect to={ROUTES.LOGIN} />;
};

export default Logout;
