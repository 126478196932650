import { useMutation } from 'react-query';
import { EmailPassword, ResetPasswordRequest } from 'core/api';
import { useApi } from 'providers';

const useLogin = () => {
  const api = useApi();

  return useMutation(
    async (newData: EmailPassword) =>
      await api.account.authenticateCreate(newData),
  );
};

export const useForgotPassword = () => {
  const api = useApi();

  const forgotPasswordMutation = useMutation((email: string) =>
    api.account.forgotPasswordCreate({ email }),
  );

  return forgotPasswordMutation;
};

export const useResetPassword = () => {
  const api = useApi();

  const resetPasswordMutation = useMutation((data: ResetPasswordRequest) =>
    api.account.resetPasswordCreate(data),
  );

  return resetPasswordMutation;
};

export default useLogin;
