import { UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';
import {
  ColorPicker,
  CustomFields,
  Editor,
  Image,
  Input,
  Toggle,
} from 'components';

import { useCustomFields, useCredentialCustomFields } from 'hooks';

import { ConfigAppEnum } from 'core';

import { FIELD_TYPES, REGISTER_OPTIONS } from '../constants';

export interface FieldProps {
  app?: ConfigAppEnum;
  name: string;
  onChange(value: unknown): void;
  register: UseFormRegister<Record<string, [unknown, string]>>;
  tooltip?: string;
  type?: string;
  value?: unknown;
}

const getValue = (value: unknown | null, key: string) => {
  if (key === 'REGISTRATION_CONFIRMATION_TEXT' && value === null)
    return 'If you don’t see the e-mail, check your junk, spam or social folders. We sent it from no-reply@verificationemail.com.';

  return value;
};

const Field = ({
  app,
  name,
  onChange,
  register,
  tooltip,
  type,
  value: initialValue,
}: FieldProps) => {
  const fieldType = FIELD_TYPES[name] ?? type;
  const inputDefaultProps: Partial<UseFormRegisterReturn> = REGISTER_OPTIONS[
    name
  ]
    ? register(name, REGISTER_OPTIONS[name])
    : {};

  const value = getValue(initialValue, name);

  switch (fieldType) {
    case 'bool':
      return (
        <Toggle
          checked={(value === true || value === 'true') as boolean}
          onChange={onChange}
          tooltip={tooltip}
        />
      );
    case 'color':
      return (
        <ColorPicker
          color={value as string}
          onChange={onChange}
          tooltip={tooltip}
        />
      );
    case 'custom-fields':
      return (
        <CustomFields
          sourceHook={useCustomFields}
          selectedCustomFields={value as string}
          onChange={onChange}
        />
      );
    case 'credential-custom-fields':
      return (
        <CustomFields
          sourceHook={useCredentialCustomFields}
          selectedCustomFields={value as string}
          onChange={onChange}
        />
      );
    case 'html':
      return <Editor value={value as string} onChange={onChange} />;
    case 'image':
      return <Image app={app} imageUrl={value as string} />;
    default:
      return (
        <Input
          {...inputDefaultProps}
          value={value as string | number}
          onChange={e => {
            onChange(e.target.value);
            inputDefaultProps.onChange?.(e);
          }}
          className={['string', 'label'].includes(fieldType) ? 'w-3/4' : 'w-80'}
          type={fieldType === 'number' ? 'number' : undefined}
          title={tooltip}
        />
      );
  }
};

export default Field;
