// src/mocks/handlers.js
import { rest } from 'msw';
import {
  AppKeyValueTypeLabel,
  CredentialMeta,
  EmailPassword,
  OnboardingDocumentDefinitionShort,
} from 'core/api';
const baseUrl = process.env.REACT_APP_API_URL;

export const handlers = [
  rest.post<EmailPassword>(
    `${baseUrl}/account/authenticate`,
    (_req, res, ctx) => {
      return res(
        ctx.json({
          accessToken: {
            jwtToken:
              'eyJraWQiOiJubW8ydVZaVG1GdEszTDJxU3QzRlFDZHVLQmxJb3pqVDJXbTVqaGQ2VXhnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhMmI4ZDUxNi00OWE4LTQzZTEtOTZkYi05ZTMwYThiNDAyODIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfMkFaU0NPZXBnIiwiY29nbml0bzp1c2VybmFtZSI6Imhydm9qZS50ZXJ6aWMtZml2ZShhdClidWxsaG9ybi5jb20iLCJjdXN0b206aXNfc3VwZXJ1c2VyIjoiMSIsImF1ZCI6IjNzN2I1dnV1a205bnRsb29zaWIwZmQ2Mm80IiwiZXZlbnRfaWQiOiI5MDY3Y2JlZS1mYWY2LTQwYTMtYTM2MC03MDBiOWI2OWZlMmQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyMDkzMDgwMSwibmFtZSI6Ikhydm9qZSIsImV4cCI6MjIyMjIyMjIyMjIyMiwiaWF0IjoxNjIwOTMwODAxLCJmYW1pbHlfbmFtZSI6IlRlcnppYyIsImN1c3RvbTpleHRlcm5hbF9pZCI6IjQiLCJlbWFpbCI6Imhydm9qZS50ZXJ6aWMtZml2ZUBidWxsaG9ybi5jb20ifQ==.UxbExxZ4lebjJNttS0t-kKQGK-BYRVmlRd8s4HN_w2ZHyRTrVbDpFQCBjdGh3f9-LpMcuc8R9qacFLv3f5neHbth8aLBVRsQWHfDCyyqaBKQYPC74CPbxAQ_jgqYqHsbVTIke22XCcWpe9WX1yTkTQBrCn-pjTbhrvdEwjNhmAWZgEK7WrEox-yFsWBI1iWu_wEVK_I2kIM0yD1_cUAvlHwPkUBBZkoeyU652US8XkrvKbHpvNUCqR9-BR6sicAuDOqMboReWD2N3vHiKhb9dKnJjRJgExv1wPRoWQmNt83fga15eakYJhVphHV83fJvu7RZV_LSAmB0cTxjclcguQ',
          },
          idToken: {
            jwtToken:
              'eyJraWQiOiJubW8ydVZaVG1GdEszTDJxU3QzRlFDZHVLQmxJb3pqVDJXbTVqaGQ2VXhnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhMmI4ZDUxNi00OWE4LTQzZTEtOTZkYi05ZTMwYThiNDAyODIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfMkFaU0NPZXBnIiwiY29nbml0bzp1c2VybmFtZSI6Imhydm9qZS50ZXJ6aWMtZml2ZShhdClidWxsaG9ybi5jb20iLCJjdXN0b206aXNfc3VwZXJ1c2VyIjoiMSIsImF1ZCI6IjNzN2I1dnV1a205bnRsb29zaWIwZmQ2Mm80IiwiZXZlbnRfaWQiOiI5MDY3Y2JlZS1mYWY2LTQwYTMtYTM2MC03MDBiOWI2OWZlMmQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyMDkzMDgwMSwibmFtZSI6Ikhydm9qZSIsImV4cCI6MjIyMjIyMjIyMjIyMiwiaWF0IjoxNjIwOTMwODAxLCJmYW1pbHlfbmFtZSI6IlRlcnppYyIsImN1c3RvbTpleHRlcm5hbF9pZCI6IjQiLCJlbWFpbCI6Imhydm9qZS50ZXJ6aWMtZml2ZUBidWxsaG9ybi5jb20ifQ==.UxbExxZ4lebjJNttS0t-kKQGK-BYRVmlRd8s4HN_w2ZHyRTrVbDpFQCBjdGh3f9-LpMcuc8R9qacFLv3f5neHbth8aLBVRsQWHfDCyyqaBKQYPC74CPbxAQ_jgqYqHsbVTIke22XCcWpe9WX1yTkTQBrCn-pjTbhrvdEwjNhmAWZgEK7WrEox-yFsWBI1iWu_wEVK_I2kIM0yD1_cUAvlHwPkUBBZkoeyU652US8XkrvKbHpvNUCqR9-BR6sicAuDOqMboReWD2N3vHiKhb9dKnJjRJgExv1wPRoWQmNt83fga15eakYJhVphHV83fJvu7RZV_LSAmB0cTxjclcguQ',
          },
          refreshToken: {
            token:
              'eyJraWQiOiJubW8ydVZaVG1GdEszTDJxU3QzRlFDZHVLQmxJb3pqVDJXbTVqaGQ2VXhnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhMmI4ZDUxNi00OWE4LTQzZTEtOTZkYi05ZTMwYThiNDAyODIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfMkFaU0NPZXBnIiwiY29nbml0bzp1c2VybmFtZSI6Imhydm9qZS50ZXJ6aWMtZml2ZShhdClidWxsaG9ybi5jb20iLCJjdXN0b206aXNfc3VwZXJ1c2VyIjoiMSIsImF1ZCI6IjNzN2I1dnV1a205bnRsb29zaWIwZmQ2Mm80IiwiZXZlbnRfaWQiOiI5MDY3Y2JlZS1mYWY2LTQwYTMtYTM2MC03MDBiOWI2OWZlMmQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyMDkzMDgwMSwibmFtZSI6Ikhydm9qZSIsImV4cCI6MjIyMjIyMjIyMjIyMiwiaWF0IjoxNjIwOTMwODAxLCJmYW1pbHlfbmFtZSI6IlRlcnppYyIsImN1c3RvbTpleHRlcm5hbF9pZCI6IjQiLCJlbWFpbCI6Imhydm9qZS50ZXJ6aWMtZml2ZUBidWxsaG9ybi5jb20ifQ==.UxbExxZ4lebjJNttS0t-kKQGK-BYRVmlRd8s4HN_w2ZHyRTrVbDpFQCBjdGh3f9-LpMcuc8R9qacFLv3f5neHbth8aLBVRsQWHfDCyyqaBKQYPC74CPbxAQ_jgqYqHsbVTIke22XCcWpe9WX1yTkTQBrCn-pjTbhrvdEwjNhmAWZgEK7WrEox-yFsWBI1iWu_wEVK_I2kIM0yD1_cUAvlHwPkUBBZkoeyU652US8XkrvKbHpvNUCqR9-BR6sicAuDOqMboReWD2N3vHiKhb9dKnJjRJgExv1wPRoWQmNt83fga15eakYJhVphHV83fJvu7RZV_LSAmB0cTxjclcguQ',
          },
        }),
      );
    },
  ),
  rest.get<AppKeyValueTypeLabel>(
    `${baseUrl}/configs?app=dhp&keys=default`,
    (_req, res, ctx) => {
      return res(
        ctx.json([
          {
            app: '',
            key: '',
            value: '',
          },
        ]),
      );
    },
  ),
  rest.get<OnboardingDocumentDefinitionShort>(
    `${baseUrl}/credential/definitions`,
    (_req, res, ctx) => {
      return res(
        ctx.json([
          {
            id: 5,
            document_name: 'doc5',
          },
        ]),
      );
    },
  ),
  rest.post<CredentialMeta>(
    `${baseUrl}/credential/metadata`,
    (req, res, ctx) => {
      return res(
        ctx.json({
          document_name: 'doc1',
          info_tip: req.body.info_tip,
          onboarding_document_definition_id:
            req.body.onboarding_document_definition_id,
        }),
      );
    },
  ),
  rest.get<CredentialMeta>(
    `${baseUrl}/credential/metadata`,
    (_req, res, ctx) => {
      return res(
        ctx.json([
          {
            document_name: 'doc1',
            info_tip: undefined,
            onboarding_document_definition_id: 1,
          },
          {
            document_name: 'doc2',
            info_tip: undefined,
            onboarding_document_definition_id: 2,
          },
          {
            document_name: 'doc3',
            info_tip: 'info about credentials',
            onboarding_document_definition_id: 3,
          },
          {
            document_name: 'doc4',
            info_tip: 'another info',
            onboarding_document_definition_id: 4,
          },
        ]),
      );
    },
  ),
];
