import { useQuery } from 'react-query';
import { CustomField } from 'core';
import { useApi } from 'providers';

const useCredentialCustomFields = () => {
  const api = useApi();

  return useQuery<CustomField[]>(
    ['credentialCustomFields', api],
    async () => {
      const { data } = await api.credential.customFieldsList();

      return data;
    },
    { cacheTime: 1000 * 60 * 30 },
  );
};

export default useCredentialCustomFields;
