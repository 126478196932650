import { Link, useLocation } from 'react-router-dom';
import { FaBuilding, FaUser, FaUserFriends } from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import classnames from 'classnames';

import { ROUTES } from '../constants';

const linkClassNames =
  'p-3 hover:bg-secondary hover:bg-opacity-70 text-gray-300 flex flex-col gap-2 items-center';

const getClassName = (currentPathname: string, pathname: string) =>
  classnames(linkClassNames, {
    'bg-secondary': currentPathname === pathname,
    'bg-primary': currentPathname !== pathname,
  });

export default function LeftNav() {
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col bg-primary">
      <Link to={ROUTES.DHP} className={getClassName(pathname, '/dhp')}>
        <FaUser />
        <div className="text-xs">VPRO</div>
      </Link>
      <Link
        to={ROUTES.FACILITY}
        className={getClassName(pathname, '/facility')}
      >
        <FaBuilding />
        <div className="text-xs">Facility</div>
      </Link>
      <Link
        to={ROUTES.DELEGATE}
        className={getClassName(pathname, '/delegate')}
      >
        <FaUserFriends />
        <div className="text-xs">Delegate</div>
      </Link>
      <Link
        to={ROUTES.SETTINGS}
        className={getClassName(pathname, '/settings')}
      >
        <BsFillGearFill />
        <div className="text-xs">App</div>
      </Link>
    </div>
  );
}
