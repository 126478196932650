import { Reducer, useCallback, useMemo, useReducer, useState } from 'react';
import { Button, Label, Loader, Modal, Select } from 'components';
import { Option } from 'components/select';
import {
  Classification,
  ClassificationGrid,
  ErrorContext,
  handleError,
} from 'core';
import {
  useClassificationCreate,
  useClassifications,
  useDeleteClassifications,
  useFacilities,
  useJobTitles,
  useVendors,
} from 'hooks';
import { toast } from 'react-toastify';
import { FaFolder } from 'react-icons/fa';

import AddClassificationModal from './add-classification-modal';
import { Filters } from '../hooks/use-classifications';

interface ReducerState {
  facilityIds?: number[];
  jobTitleIds?: number[];
  vendorIds?: number[];
}

type ReducerAction =
  | { type: 'setFacilityIds'; payload: number[] }
  | { type: 'setJobTitleIds'; payload: number[] }
  | { type: 'setVendorIds'; payload: number[] }
  | { type: 'reset' };

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'setFacilityIds':
      return { ...state, facilityIds: action.payload };
    case 'setJobTitleIds':
      return { ...state, jobTitleIds: action.payload };
    case 'setVendorIds':
      return { ...state, vendorIds: action.payload };
    case 'reset':
      return {};
    default:
      return state;
  }
};

export default function Classifications() {
  const [filtersDraft, updateFilters] = useReducer(reducer, {});
  const [filters, setFilters] = useState<Filters>({});
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedClassifications, setSelectedClassifications] = useState<
    ClassificationGrid[]
  >([]);
  const { mutateAsync: createClassification } = useClassificationCreate();
  const { data, isLoading, refetch } = useClassifications(filters);
  const [facilitiesSearchTerm, setFacilitiesSearchTerm] = useState<string>();
  const [jobTitlesSearchTerm, setJobTitlesSearchTerm] = useState<string>();
  const [vendorsSearchTerm, setVendorsSearchTerm] = useState<string>();
  const { data: facilities, isLoading: isLoadingFacilities } = useFacilities(
    facilitiesSearchTerm,
  );
  const { data: jobTitles, isLoading: isLoadingJobTitles } = useJobTitles(
    jobTitlesSearchTerm,
  );
  const { data: vendors, isLoading: isLoadingVendors } = useVendors(
    vendorsSearchTerm,
  );
  const {
    mutateAsync: deleteClassifications,
    isLoading: isDeleting,
  } = useDeleteClassifications();
  const facilityOptions: Option<number>[] = useMemo(
    () => facilities?.map(({ id, name }) => ({ name, value: id })) ?? [],
    [facilities],
  );
  const jobTitleOptions: Option<number>[] = useMemo(
    () => jobTitles?.map(({ id, name }) => ({ name, value: id })) ?? [],
    [jobTitles],
  );
  const vendorOptions: Option<number>[] = useMemo(
    () => [
      { name: 'Self-registered', value: 0 },
      ...(vendors?.map(({ id, name }) => ({ name, value: id })) ?? []),
    ],
    [vendors],
  );
  const isLoadingFilters = useMemo(
    () => isLoadingFacilities || isLoadingJobTitles || isLoadingVendors,
    [isLoadingFacilities, isLoadingJobTitles, isLoadingVendors],
  );
  const canFilter = useMemo(
    () =>
      filtersDraft.facilityIds?.[0] !== undefined ||
      filtersDraft.vendorIds?.[0] !== undefined,
    [filtersDraft.facilityIds, filtersDraft.vendorIds],
  );

  const handleAdd = useCallback(
    async (classification: Classification, addAnother?: boolean) => {
      try {
        await createClassification(classification);

        if (!addAnother) {
          setCreateModalVisible(false);
        }

        await refetch();
        toast.success('Successfully added a classification');
      } catch (error) {
        handleError(error as Error, ErrorContext.AddClassification);
      }
    },
    [createClassification, refetch],
  );

  const handleApplyFilters = useCallback(() => {
    setFilters(filtersDraft);
  }, [filtersDraft]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteModalVisible(false);
      await deleteClassifications(selectedClassifications);
      setSelectedClassifications([]);
      await refetch();
      toast.success('Successfully deleted classification');
    } catch (error) {
      handleError(error as Error, ErrorContext.DeleteClassifications);
    }
  }, [deleteClassifications, refetch, selectedClassifications]);

  const handleSelect = useCallback(
    (classification: ClassificationGrid) => {
      if (selectedClassifications.includes(classification)) {
        setSelectedClassifications(
          selectedClassifications.filter(x => x !== classification),
        );
      } else {
        setSelectedClassifications([
          ...selectedClassifications,
          classification,
        ]);
      }
    },
    [selectedClassifications],
  );

  return (
    <div className="flex flex-col w-screen pr-32 gap-4">
      {isLoading || isLoadingFilters ? <Loader /> : null}
      {createModalVisible && (
        <AddClassificationModal
          initialState={{
            facilitiesSearchTerm,
            jobTitlesSearchTerm,
            vendorsSearchTerm,
            facilityId: filtersDraft.facilityIds?.[0],
            jobTitleId: filtersDraft.jobTitleIds?.[0],
            vendorId: filtersDraft.vendorIds?.[0],
          }}
          onClose={() => setCreateModalVisible(false)}
          onAdd={handleAdd}
        />
      )}
      {deleteModalVisible && (
        <Modal
          closeLabel="Cancel"
          onClose={() => setDeleteModalVisible(false)}
          SubmitButton={
            <Button disabled={isDeleting} onClick={handleDelete}>
              Yes
            </Button>
          }
          title="Delete Classifications"
        >
          Are you sure you want to delete {selectedClassifications.length} rows?
        </Modal>
      )}
      <div className="flex gap-2 py-4 items-center flex-wrap">
        <Label label="Classification:">
          <Select
            options={jobTitleOptions}
            value={filtersDraft.jobTitleIds?.[0]}
            onChange={value => {
              updateFilters({ type: 'setJobTitleIds', payload: [value] });
              setSelectedClassifications([]);
            }}
            onSearch={setJobTitlesSearchTerm}
          />
        </Label>
        <Label label="Facility:">
          <Select
            options={facilityOptions}
            value={filtersDraft.facilityIds?.[0]}
            onChange={value => {
              updateFilters({ type: 'setFacilityIds', payload: [value] });
              setSelectedClassifications([]);
            }}
            onSearch={setFacilitiesSearchTerm}
          />
        </Label>
        <Label label="Vendor:">
          <Select
            options={vendorOptions}
            value={filtersDraft.vendorIds?.[0]}
            onChange={value => {
              updateFilters({ type: 'setVendorIds', payload: [value] });
              setSelectedClassifications([]);
            }}
            onSearch={setVendorsSearchTerm}
          />
        </Label>
        <Button
          className="mt-3 border-none shadow"
          variant="outline"
          disabled={!canFilter}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="mt-3 border-none shadow"
          variant="outline"
          onClick={() => {
            updateFilters({ type: 'reset' });
            setSelectedClassifications([]);
          }}
        >
          Reset Filters
        </Button>
      </div>
      <div className="flex justify-between items-center">
        <div className="text-secondary font-light">
          {selectedClassifications.length} rows selected
        </div>
        <Button
          variant="outline"
          onClick={() => setDeleteModalVisible(true)}
          disabled={selectedClassifications.length === 0}
        >
          Delete Selected
        </Button>
      </div>
      <table className="table-row h-80 overflow-y-auto relative border">
        <thead>
          <tr>
            <th className="border-r border-b p-2 w-8"></th>
            <th className="border-r border-b p-2 w-1/3">Classification</th>
            <th className="border-r border-b p-2 w-1/3">Facility</th>
            <th className="border-b p-2 w-1/3">Vendor</th>
          </tr>
        </thead>
        <tbody>
          {!data?.length && (
            <div className="top-1/2 left-1/2 absolute text-gray-200 flex gap-2 items-center text-3xl -ml-40 font-thin">
              <FaFolder />
              <span>Nothing to show here...</span>
            </div>
          )}
          {data?.map(classification => {
            const {
              facility,
              facility_id,
              jobtitle,
              jobtitle_id,
              vendor,
              vendor_id,
            } = classification;

            return (
              <tr
                key={`${jobtitle_id}-${facility_id}-${vendor_id}`}
                className="h-10"
              >
                <td className="border-r border-b p-2 w-8">
                  <input
                    type="checkbox"
                    onChange={() => handleSelect(classification)}
                    checked={selectedClassifications.includes(classification)}
                  />
                </td>
                <td className="border p-2 w-1/3">{jobtitle}</td>
                <td className="border p-2 w-1/3">{facility}</td>
                <td className="border p-2 w-1/3">{vendor}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between py-2 border-t-2 border-gray-50">
        <div className="font-semibold text-gray-700">
          Showing {data?.length ?? 0} results
        </div>
        <Button
          className="self-end"
          variant="secondary"
          onClick={() => setCreateModalVisible(true)}
        >
          Add New Mapping
        </Button>
      </div>
    </div>
  );
}
