import { useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useApi } from 'providers';
import { AUTH } from '../constants';

type CognitoAuthenticationResponse = {
  auth_time: number;
  'cognito:username': string;
  'custom:external_id': number;
  'custom:is_superuser': '0' | '1';
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  iat: number;
  iss: string;
  name: string;
};

type UserAuth = {
  isAuthenticated: boolean;
  isSuperUser: boolean;
  claims?: CognitoAuthenticationResponse;
};

const decodeJwtToken = (token: string): CognitoAuthenticationResponse => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');

  return JSON.parse(window.atob(base64));
};

export const getClaims = (token: string) => {
  const userClaims = decodeJwtToken(token);
  const now = Math.floor(new Date().valueOf() / 1000);
  if (
    userClaims.iss === process.env.REACT_APP_COGNITO_ISSUER &&
    now < userClaims.exp
  ) {
    return userClaims;
  }
};

let userAuth: UserAuth = {
  isAuthenticated: false,
  isSuperUser: false,
};

const useUserAuth = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  const [user, setUser] = useState(userAuth);

  const setUserState = useCallback((data: UserAuth) => {
    setUser(prevState => {
      return { ...prevState, ...data };
    });
  }, []);

  const authorize = useCallback(
    async (token?: string | null) => {
      if (!token) {
        token = localStorage.getItem(AUTH.ID_TOKEN);
      }
      if (token) {
        queryClient.setQueryData(AUTH.ID_TOKEN, token);
        localStorage.setItem(AUTH.ID_TOKEN, token);
        api.setSecurityData({ jwtToken: token });
        userAuth.claims = getClaims(token);
        if (userAuth.claims) {
          userAuth.isAuthenticated = true;
          userAuth.isSuperUser = userAuth.claims['custom:is_superuser'] === '1';
        }
      }
      return setUserState(userAuth);
    },
    [api, queryClient, setUserState],
  );

  useEffect(() => {
    authorize();
  }, [authorize]);

  return { user, authorize };
};

export default useUserAuth;
