import { useCallback, useState } from 'react';
import { Switch } from '@headlessui/react';

export interface ToggleProps {
  onChange?(checked: boolean): void;
  checked?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export default function Toggle({
  checked,
  disabled,
  onChange,
  tooltip,
}: ToggleProps) {
  const [enabled, setEnabled] = useState(checked);

  const handleChange = useCallback(
    (checked: boolean) => {
      setEnabled(checked);
      onChange?.(checked);
    },
    [onChange],
  );

  return (
    <Switch
      checked={!!enabled}
      disabled={disabled}
      onChange={handleChange}
      title={tooltip}
      className={`${enabled ? 'bg-green-500' : 'bg-primary opacity-70'}
          relative inline-flex flex-shrink-0 h-full w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-7' : 'translate-x-0'}
            pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
      />
    </Switch>
  );
}
