import { useMutation } from 'react-query';
import { Account } from 'core';
import { useApi } from 'providers';

const useSuperUserCreate = () => {
  const api = useApi();

  return useMutation(async (data: Account) =>
    api.superUsers.superUsersCreate(data),
  );
};

export default useSuperUserCreate;
