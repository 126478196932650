import { Redirect, Route, Switch } from 'react-router-dom';
import { DynamicConfig } from 'components';
import { ConfigAppEnum } from 'core';
import { Settings } from 'pages';

import { ROUTES } from '../constants';

export default function Content() {
  return (
    <Switch>
      <Route
        path={ROUTES.DELEGATE}
        component={() => <DynamicConfig app={ConfigAppEnum.Delegate} />}
      />
      <Route
        path={ROUTES.DHP}
        component={() => <DynamicConfig app={ConfigAppEnum.Dhp} />}
      />
      <Route
        path={ROUTES.FACILITY}
        component={() => <DynamicConfig app={ConfigAppEnum.Facility} />}
      />
      <Route path={ROUTES.SETTINGS} component={() => <Settings />} />
      <Redirect to={ROUTES.DHP} />
    </Switch>
  );
}
