import { useQuery } from 'react-query';
import { SuperUserInfo } from 'core';
import { useApi } from 'providers';

const useClassifications = () => {
  const api = useApi();

  return useQuery<SuperUserInfo[]>(
    ['superUsers', api],
    async () => {
      const { data } = await api.superUsers.superUsersList();

      return data;
    },
    {
      cacheTime: 1000 * 60 * 30,
    },
  );
};

export default useClassifications;
