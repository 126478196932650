import { useCallback, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';

export interface ColorPickerProps {
  color?: string;
  onChange?(color: string): void;
  tooltip?: string;
}

export default function ColorPicker({
  onChange,
  tooltip,
  color: initialColor,
}: ColorPickerProps) {
  const [color, setColor] = useState(initialColor ?? 'white');
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handleChangeComplete: ColorChangeHandler = useCallback(
    ({ hex }) => {
      setColor(hex);
      onChange?.(hex);
    },
    [onChange],
  );

  return (
    <div className="flex gap-4 p-2 items-center relative" title={tooltip}>
      <div
        className="h-5 w-5 border-2 border-gray-400 cursor-pointer"
        style={{ backgroundColor: color }}
        onClick={() => setColorPickerVisible(true)}
      />
      <div className="text-gray-500 font-bold">{color}</div>
      {colorPickerVisible && (
        <div className="absolute z-10">
          <div
            className="fixed w-screen h-screen top-0 left-0 z-0"
            onClick={() => setColorPickerVisible(false)}
          />
          <div className="z-20 mb-80">
            <SketchPicker
              color={color}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>
      )}
    </div>
  );
}
