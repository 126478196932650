import { RegisterOptions } from 'react-hook-form';
import { FieldType } from 'core';

export const AUTH = {
  ID_TOKEN: 'idToken',
  AUTHENTICATION_ROUTE: '/account/authenticate',
};

export const LABELS: Record<string, string> = {
  'CREDENTIALS_CAPTIONS-DESCRIPTION': 'Credentials Captions Description',
  'LOGIN_CUSTOM_TEXT-AGENCY_TEXT': ' Login Screen - Agency Text',
  'PAYMENT_TYPE_LABELS-Vendor': 'Payment Type Label - Vendor',
  'PAYMENT_TYPE_LABELS-VPRO': 'Payment Type Label - VPRO',
  ACCESS_INFO_FIELDS_CHANGE_MESSAGE: 'Access Info - Fields Change Message',
  DEFAULT_USER_STATUS_ID: 'Default User Status ID',
  GLOBAL_PAYMENTS_ENABLED: 'Global Payments Enabled',
  LOGO_FILE: 'Logo',
  PAYMENT_EXPIRATION_WARNING_NUMBER_OF_DAYS:
    'Payment Expiration Threshold (days)',
  PAYMENT_HELP_TOOLTIP_TEXT: 'Payment Help Tooltip Text',
  SKIP_ONBOARDING: 'Skip Onboarding',
  TAS: 'Terms and Services',
  VPRO_CUSTOM_FIELDS: 'Custom Fields',
  CREDENTIAL_CUSTOM_FIELDS: 'Credential Custom Fields',
  REGISTRATION_CONFIRMATION_TEXT: 'Registration confirmation text',
};

export const APP_CONFIG_KEYS = {
  TAS: 'TAS',
};

export const FIELD_TYPES: Record<string, FieldType> = {
  LOGO_FILE: 'image',
  VPRO_CUSTOM_FIELDS: 'custom-fields',
  TAS: 'html',
  CREDENTIAL_CUSTOM_FIELDS: 'credential-custom-fields',
  REGISTRATION_CONFIRMATION_TEXT: 'html',
};

export const REGISTER_OPTIONS: Record<string, RegisterOptions> = {
  DEFAULT_USER_STATUS_ID: { required: false },
};

export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  DHP: '/dhp',
  FACILITY: '/facility',
  DELEGATE: '/delegate',
  SETTINGS: '/settings',
};
