import { SyntheticEvent, useCallback, useState } from 'react';
import ReactQuill from 'react-quill';
import { ReactQuillProps } from 'react-quill/lib/index';

import Button from './button';

import 'react-quill/dist/quill.snow.css';

export interface EditorProps extends Omit<ReactQuillProps, 'onChange'> {
  onChange?(value: string): void;
}

export default function Editor({
  onChange,
  value,
  ...reactQuillProps
}: EditorProps) {
  const [viewHtml, setViewHtml] = useState(false);
  const [html, setHtml] = useState(value);

  const handleChange = useCallback(
    (html: string) => {
      setHtml(html);
      onChange?.(html);
    },
    [onChange],
  );

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setViewHtml(!viewHtml);
  };

  return (
    <div className="flex flex-col gap-4 w-3/4 mb-10">
      <Button variant="secondary" onClick={handleClick}>
        View {viewHtml ? 'Editor' : 'HTML'}
      </Button>
      {viewHtml ? (
        <span
          className="w-full rounded shadow p-2 font-mono overflow-scroll"
          style={{ height: 400 }}
        >
          {html}
        </span>
      ) : (
        <ReactQuill
          {...reactQuillProps}
          style={{ height: 400 }}
          value={html}
          onChange={handleChange}
          theme="snow"
        />
      )}
    </div>
  );
}
