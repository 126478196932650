import { toast } from 'react-toastify';

export enum ErrorContext {
  AddClassification,
  Authorization,
  CreateSuperUser,
  DeleteClassifications,
  ImageUpload,
  SaveConfiguration,
  ToggleSuperUser,
  EditCredentialsMeta,
}

const errorMessages: Map<ErrorContext, string> = new Map([
  [
    ErrorContext.CreateSuperUser,
    'Unable to create super user. Please try again or contact support.',
  ],
  [
    ErrorContext.AddClassification,
    'Unable to add a classification. Please try again or contact support.',
  ],
  [ErrorContext.Authorization, 'Authorization failed. Please try again.'],
  [
    ErrorContext.DeleteClassifications,
    'Unable to delete classifications. Please try again or contact support.',
  ],
  [ErrorContext.ImageUpload, 'Unable to upload image'],
  [ErrorContext.SaveConfiguration, 'Unable to save config'],
  [ErrorContext.ToggleSuperUser, 'Unable to toggle super user'],
  [
    ErrorContext.EditCredentialsMeta,
    'Unable to edit credential details. Please try again or contact support.',
  ],
]);

export const handleError = (error: Error, context: ErrorContext) => {
  const errorMessage = errorMessages.get(context) ?? error?.message;

  console.warn('handleError', JSON.stringify(error, null, 2));
  toast.error(errorMessage);
};
