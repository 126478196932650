import { useQuery } from 'react-query';
import { CustomField } from 'core';
import { useApi } from 'providers';

const useCustomFields = () => {
  const api = useApi();

  return useQuery<CustomField[]>(
    ['customFields', api],
    async () => {
      const {
        data,
      } = await api.candidateCustomFields.candidateCustomFieldsList();

      return data;
    },
    { cacheTime: 1000 * 60 * 30 },
  );
};

export default useCustomFields;
