import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classnames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { HiCheck, HiSelector } from 'react-icons/hi';
import { useDebounce } from 'react-use'

import Input from './input';

export interface Option<T> {
  name: string;
  value: T;
}

export interface SelectProps<T> {
  disabled?: boolean;
  onChange?(value: T): void;
  onSearch?(searchTerm?: string): void;
  options: Option<T>[];
  value?: T;
}

export default function Select<T>({
  disabled,
  onChange,
  onSearch,
  options,
  value,
}: SelectProps<T>) {
  const [selected, setSelected] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 30));
  const [searchTerm, setSearchTerm] = useState<string>();
  // selectedName is intentionally memoized by value and selected to preserve name after options change
  const selectedName = useMemo(
    () => options?.find(({ value }) => value === selected)?.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, value],
  );

  const handleChange = useCallback(
    (value: T) => {
      setSelected(value);
      onChange?.(value);
    },
    [onChange],
  );

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      setSearchTerm(value);

      if (!onSearch) {
        setFilteredOptions(
          options
            .filter(({ name }) =>
              name?.toLowerCase()?.includes(value?.trim()?.toLowerCase()),
            )
            .slice(0, 30),
        );
      }
    },
    [onSearch, options],
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options.slice(0, 30));
  }, [options]);

  useDebounce(() => {
    onSearch?.(searchTerm)
  }, 500, [onSearch, searchTerm])

  return (
    <div className="w-72">
      <Listbox value={selected} onChange={handleChange} disabled={disabled}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button className="relative h-10 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span
                  className={classnames('block truncate', {
                    'text-gray-300': !selectedName,
                  })}
                >
                  {selectedName ?? 'Select...'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <HiSelector
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute w-full py-1 mt-1 text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                >
                  <div className="flex flex-col gap-2">
                    <Input
                      className="mx-2"
                      placeholder={
                        onSearch
                          ? 'Start typing to see results...'
                          : 'Filter...'
                      }
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                    <div className="overflow-auto max-h-60">
                      {filteredOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `${
                              active
                                ? 'text-amber-900 bg-secondary opacity-70 text-white'
                                : 'text-gray-900'
                            } cursor-default select-none relative py-2 pl-10 pr-4`
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${
                                  selected ? 'font-medium' : 'font-normal'
                                } block truncate`}
                              >
                                {option.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`${
                                    active ? 'text-amber-600' : 'text-amber-600'
                                  } absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <HiCheck
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </div>
                  </div>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
