import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import classnames from 'classnames';

export interface BaseTabHeaderProps {
  title: string;
}

interface TabHeaderProps extends BaseTabHeaderProps {
  index: number;
  selectedTab?: number;
  setSelectedTab: (index: number) => void;
}

const TabHeader: FC<TabHeaderProps> = ({
  title,
  selectedTab,
  setSelectedTab,
  index,
}) => {
  const onClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setSelectedTab(index);
    },
    [setSelectedTab, index],
  );

  const getTabClassName = useMemo(
    () =>
      classnames('text-base font-normal pr-3 py-2 block cursor-pointer ', {
        'text-primary border-b-4 border-primary': selectedTab === index,
        'text-black': selectedTab !== index,
      }),
    [index, selectedTab],
  );

  return (
    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
      <p
        className={getTabClassName}
        onClick={onClick}
        data-toggle="tab"
        role="tablist"
      >
        {title}
      </p>
    </li>
  );
};

export default TabHeader;
