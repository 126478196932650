import { useMutation } from 'react-query';
import { Classification } from 'core';
import { useApi } from 'providers';

const useDeleteClassifications = () => {
  const api = useApi();

  return useMutation(async (classifications: Classification[]) =>
    Promise.all(
      classifications.map(classification =>
        api.classification.classificationDelete(classification),
      ),
    ),
  );
};

export default useDeleteClassifications;
