import { FC } from 'react';

const Label: FC<{ label: string; color?: string }> = ({
  children,
  label,
  color,
}) => (
  <div className="flex flex-col gap-0 pb-2 relative">
    <div className={`${color || 'text-secondary'} text-xs font-semibold`}>
      {label}
    </div>
    {children}
  </div>
);

export default Label;
