import { useMutation } from 'react-query';
import { Classification } from 'core';
import { useApi } from 'providers';

const useClassificationCreate = () => {
  const api = useApi();

  return useMutation(async (classification: Classification) =>
    api.classification.classificationCreate(classification),
  );
};

export default useClassificationCreate;
