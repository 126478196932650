import { createContext, FC, useContext } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Api } from 'core';
import { AUTH } from '../constants';

const api = new Api<{ jwtToken: string }>({
  baseApiParams: { secure: true },
  baseUrl: process.env.REACT_APP_API_URL,
  securityWorker: securityData =>
    securityData
      ? { headers: { Authorization: securityData.jwtToken } }
      : undefined,
});
const ApiContext = createContext(api);
const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    mutations: {
      onError: (error: any, variables, context) => {
        // don't reload on login
        if (
          error.status === 401 &&
          error.url !== `${api.baseUrl}${AUTH.AUTHENTICATION_ROUTE}`
        ) {
          api.setSecurityData(null);
          queryClient.clear();
          localStorage.removeItem(AUTH.ID_TOKEN);
          window.location.reload();
        }
      },
    },
  },
});

export const ApiProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export const useApi = () => useContext(ApiContext);
