import { InputHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';
import classnames from 'classnames';

const Input = (
  props: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  ref: Ref<HTMLInputElement>,
) => {
  const { className: baseClassName } = props;
  const className = classnames(
    baseClassName,
    'shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
  );

  return <input {...props} ref={ref} className={className} />;
};

export default forwardRef(Input);
