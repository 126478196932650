import { useMutation } from 'react-query';
import { SaveAppKeyValue } from 'core';
import { useApi } from 'providers';

const useConfigsMutation = () => {
  const api = useApi();

  return useMutation((saveAppKeyValue: SaveAppKeyValue) =>
    api.configs.configsCreate(saveAppKeyValue),
  );
};

export default useConfigsMutation;
