import { useMutation } from 'react-query';
import { Base64File } from 'core';
import { useApi } from 'providers';

const useUploadImage = () => {
  const api = useApi();

  return useMutation(async (base64File: Required<Base64File>) =>
    api.images.imagesCreate(base64File),
  );
};

export default useUploadImage;
