import { useCallback, useState } from 'react';
import { Button, CreateSuperUserModal, Loader, Toggle } from 'components';
import { Account, ErrorContext, handleError } from 'core';
import {
  useSuperUserCreate,
  useSuperUsers,
  useToggleSuperUser,
  useUserAuth,
} from 'hooks';
import { FaFolder } from 'react-icons/fa';
import { toast } from 'react-toastify';

export default function SuperUsers() {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const { data, isLoading, refetch } = useSuperUsers();
  const { mutateAsync: createSuperUser } = useSuperUserCreate();
  const {
    mutateAsync: toggleSuperUser,
    isLoading: isTogglingSuperUser,
  } = useToggleSuperUser();
  const {
    user: { isSuperUser },
  } = useUserAuth();

  const handleCreate = useCallback(
    async (data: Account) => {
      try {
        await createSuperUser(data);
        setCreateModalVisible(false);
        await refetch();
        toast.success('Successfully added super user');
      } catch (error: unknown) {
        handleError(error as Error, ErrorContext.CreateSuperUser);
      }
    },
    [createSuperUser, refetch],
  );

  const handleToggleSuperUser = useCallback(
    async (email: string, enabled: boolean) => {
      try {
        await toggleSuperUser({ email, enabled });
        refetch();
      } catch (error: unknown) {
        handleError(error as Error, ErrorContext.ToggleSuperUser);
      }
    },
    [refetch, toggleSuperUser],
  );

  return (
    <div className="flex flex-col w-screen pr-32 gap-4">
      {isLoading ? <Loader /> : null}
      {createModalVisible && (
        <CreateSuperUserModal
          onClose={() => setCreateModalVisible(false)}
          onCreate={handleCreate}
        />
      )}
      <table
        className="table-row break-all overflow-y-auto relative border"
        style={{ minHeight: 500 }}
      >
        <thead>
          <tr>
            <th className="border-r border-b p-2 w-1/3">First name</th>
            <th className="border-r border-b p-2 w-1/3">Last name</th>
            <th className="border-b border-r p-2">Email</th>
            <th className="border-b border-r p-2">Is Super User?</th>
            {isSuperUser ? (
              <th className="border-b border-r p-2">Enabled</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {!data?.length && (
            <div className="top-1/2 left-1/2 absolute text-gray-200 flex gap-2 items-center text-3xl -ml-40 font-thin">
              <FaFolder />
              <span>Nothing to show here...</span>
            </div>
          )}
          {data?.map(superUser => {
            const { email, enabled, first, is_superuser, last } = superUser;

            return (
              <tr key={`${email}-${first}-${last}`} className="h-10">
                <td className="border p-2 w-1/3">{first}</td>
                <td className="border p-2 w-1/3">{last}</td>
                <td className="border p-2">{email}</td>
                <td
                  className={`border p-2 font-bold ${
                    is_superuser ? 'text-green-500' : 'text-yellow-500'
                  }`}
                >
                  {is_superuser ? 'Yes' : 'No'}
                </td>
                {isSuperUser ? (
                  <td className="border p-2">
                    <Toggle
                      checked={enabled}
                      disabled={isTogglingSuperUser}
                      onChange={() => handleToggleSuperUser(email, !enabled)}
                    />
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between py-2 border-t-2 border-gray-50">
        <div className="font-semibold text-gray-700">
          Showing {data?.length ?? 0} results
        </div>
        {isSuperUser ? (
          <Button
            className="self-end"
            variant="secondary"
            onClick={() => setCreateModalVisible(true)}
          >
            Add New Admin User
          </Button>
        ) : null}
      </div>
    </div>
  );
}
