import { Link } from 'react-router-dom';
import { Button } from 'components';
import { version } from 'version.json';

export interface HeaderProps {
  logoUrl: string;
  user?: {
    fullName: string;
    email: string;
  };
}

export default function Header({ logoUrl, user }: HeaderProps) {
  return (
    <nav className="flex justify-between px-10 py-2 bg-white shadow h-14">
      <div className="flex justify-items-start">
        <Link to="/">
          <img className="h-full" width="125" src={logoUrl} alt="Main logo" />
        </Link>
        <div className="flex ml-2.5 text-xs">{`v${version}`}</div>
      </div>
      {user && (
        <div className="flex gap-4">
          <div className="flex flex-col text-xs">
            <div>{user.fullName}</div>
            <div className="text-gray-600">{user.email}</div>
          </div>
          <a href="/logout">
            <Button>Log Out</Button>
          </a>
        </div>
      )}
    </nav>
  );
}
