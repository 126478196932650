import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ErrorContext, handleError } from 'core';
import { useResetPassword } from 'hooks';
import { toast } from 'react-toastify';
import { Input, Button, Error, Loader } from 'components';
import withPublicLayout from 'layouts/with-public-layout';

import { ROUTES } from '../constants';

type ResetPasswordData = {
  email: string;
  new_password: string;
  verification_code: string;
};

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { mutateAsync } = useResetPassword();

  const onSubmit = async (data: ResetPasswordData) => {
    const { email, new_password, verification_code } = data;

    try {
      await mutateAsync({ email, new_password, verification_code });
      toast.success('Congrats. You can now log in with your new password.');
    } catch (error) {
      handleError(error, ErrorContext.Authorization);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="flex justify-center">
      <form className="w-72 m-5 py-5 flex flex-col gap-5 ">
        <p className="font-bold text-primary text-xl text-center">
          Set new password
        </p>
        <Input
          {...register('email', { required: true })}
          id="email"
          className="rounded shadow p-2"
          placeholder="Email"
          autoComplete="email"
        />
        {errors.email && <Error message={'Email is required'} />}
        <Input
          {...register('new_password', { required: true, minLength: 6 })}
          id="password"
          className="rounded shadow p-2"
          placeholder="New password"
          type="password"
          autoComplete="new-password"
        />
        {errors.new_password && (
          <Error message={'Password of at least 6 characters is required'} />
        )}
        <Input
          {...register('verification_code', { required: true })}
          id="password"
          className="rounded shadow p-2"
          placeholder="Verification code"
          type="text"
          autoComplete="verification-code"
        />
        {errors.verification_code && (
          <Error message={'Verification code is required'} />
        )}
        <div className="flex flex-row justify-between items-baseline">
          <Link
            to={ROUTES.LOGIN}
            className="text-secondary font-semibold cursor-pointer"
          >
            Back to Log in
          </Link>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Reset password
          </Button>
        </div>
      </form>
    </div>
  );
};
export default withPublicLayout(ResetPassword);
