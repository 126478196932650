import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { Button, Error, Field, Loader } from 'components';
import {
  AppKeyValue,
  AppKeyValueTypeLabel,
  ConfigAppEnum,
  ErrorContext,
  handleError,
} from 'core';
import { useConfigs, useConfigsMutation } from 'hooks';
import { toast } from 'react-toastify';

import { LABELS } from '../constants';

export interface DynamicConfigProps {
  app?: ConfigAppEnum | undefined;
  keys?: Array<string>;
}

type ReducerAction =
  | {
      type: 'onChange';
      payload: { key: string; value: unknown };
    }
  | { type: 'initialize'; payload: Record<string, AppKeyValueTypeLabel> };

const reducer: Reducer<Record<string, AppKeyValueTypeLabel>, ReducerAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'onChange': {
      const { key, value } = action.payload;
      const entry = state[key];

      return {
        ...state,
        [key]: { ...entry, value: value as string | undefined },
      };
    }
    case 'initialize': {
      return action.payload;
    }
    default:
      return state;
  }
};

const getLabel = (key: string, { label }: AppKeyValueTypeLabel) => {
  if (label) return label;

  const userStatusColorRegex = /USER_STATUS_COLORS-(?<statusId>\d+)/;

  if (userStatusColorRegex.test(key)) {
    return `User Status Color for ID: ${
      key.match(userStatusColorRegex)?.groups?.statusId
    }`;
  }

  return LABELS[key] ?? key;
};

export default function DynamicConfig({ app, keys }: DynamicConfigProps) {
  const [state, dispatch] = useReducer(reducer, {});
  const [changes, setChanges] = useState(new Set<string>([]));
  const { data, isFetched, isLoading, refetch } = useConfigs(
    app,
    keys ?? ['default'],
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, [unknown, string]>>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { mutateAsync, isLoading: isMutating } = useConfigsMutation();

  const handleChange = useCallback(
    (key: string, value: unknown) => {
      dispatch({ type: 'onChange', payload: { key, value } });
      setChanges(new Set([key, ...Array.from(changes)]));
    },
    [changes],
  );

  const handleSave = useCallback(async () => {
    try {
      const records: AppKeyValue[] = Object.entries(state)
        .filter(([key]) => changes.has(key))
        .map(([key, { value }]) => ({
          app,
          key,
          value,
        }));

      if (!records.length) return;

      await mutateAsync({
        app,
        records,
      });
      toast.success('Saved successfully');
      refetch();
      setChanges(new Set<string>([]));
    } catch (error: unknown) {
      handleError(error as Error, ErrorContext.SaveConfiguration);
    }
  }, [app, changes, mutateAsync, refetch, state]);

  useEffect(() => {
    if (data && isFetched) {
      let filteredData = data;

      if (keys) {
        filteredData = data.filter(appKey =>
          keys.find(item => item === appKey.key),
        );
      }

      dispatch({
        type: 'initialize',
        payload: Object.fromEntries(
          filteredData.map(entry => [entry.key, entry]),
        ),
      });
    }
  }, [data, isFetched, keys]);

  if (!state) return null;

  return (
    <form className="flex flex-col gap-4 w-screen p-2">
      {isLoading || isMutating ? <Loader /> : null}
      <Button
        className="opacity-50 hover:opacity-100 fixed right-10 bottom-5 flex gap-2 items-center z-50"
        variant="secondary"
        onClick={handleSubmit(handleSave)}
        disabled={changes.size === 0}
        type="submit"
      >
        <FaSave size={14} /> Save
      </Button>
      <div className="text-2xl text-primary font-bold filter drop-shadow">
        {(app && `${app.toUpperCase()} Settings`) || ''}
      </div>
      {Object.entries(state).map(([key, entry]) => {
        if (!key) return null;

        return (
          <div key={key} className="flex flex-col gap-0 pb-2 relative">
            <div className="text-secondary text-xs font-semibold">
              {getLabel(key, entry)}:
            </div>
            <div>
              <Field
                app={app}
                name={key}
                onChange={value => handleChange(key, value)}
                register={register}
                tooltip={entry.tooltip}
                type={entry.type}
                value={entry.value}
              />
              {errors[key] && (
                <div className="absolute bottom--0 text-xs">
                  <Error message="Value not valid..." />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </form>
  );
}
