import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Login, Logout, ForgotPassword, ResetPassword } from 'pages';
import ProtectedRoute from 'core/protected-route';
import { ROUTES } from './constants';

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_MOCK === 'enabled') {
      const { worker } = require('./mocks/browser');
      worker.start();
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={ROUTES.LOGOUT} component={Logout} />
        <ProtectedRoute path="/" />
        <Route path="*" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
