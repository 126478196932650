import { useQuery } from 'react-query';
import { CredentialMetaExtended } from 'core';
import { useApi } from 'providers';

const useCredentialsMeta = () => {
  const api = useApi();

  return useQuery<CredentialMetaExtended[]>(
    ['credentialsMeta', api],
    async () => {
      const { data } = await api.credential.metadataList();

      return data;
    },
    {
      cacheTime: 1000 * 60 * 30,
    },
  );
};

export default useCredentialsMeta;
