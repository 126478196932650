import { useQuery } from 'react-query';
import { ClassificationGrid } from 'core';
import { useApi } from 'providers';

export type Filters = {
  facilityIds?: number[];
  jobTitleIds?: number[];
  vendorIds?: number[];
};

const useClassifications = (filters: Filters) => {
  const api = useApi();

  return useQuery<ClassificationGrid[]>(
    [`classifications/${JSON.stringify(filters)}`, api, filters],
    async () => {
      const { data } = await api.classification.classificationList({
        facility_ids: filters.facilityIds?.join(','),
        jobtitle_ids: filters.jobTitleIds?.join(','),
        vendor_ids: filters.vendorIds?.join(','),
      });

      return data;
    },
    {
      enabled:
        !!filters.facilityIds || !!filters.jobTitleIds || !!filters.vendorIds,
    },
  );
};

export default useClassifications;
