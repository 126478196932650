import { useMemo, useState, useEffect, useCallback } from 'react';
import { FaFolder, FaPencilAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  useCredentialsDefinitions,
  useCredentialsMeta,
  useCredentialsMetaCreate,
} from 'hooks';
import { Button, Label, Loader, Modal, Select, Input } from 'components';
import { Option } from 'components/select';
import {
  CredentialMetaExtended,
  CredentialMeta,
  ErrorContext,
  handleError,
} from 'core';

export default function Credentials() {
  const { data: onboardingDocumentDefinitions } = useCredentialsDefinitions();
  const { data, isLoading, refetch } = useCredentialsMeta();
  const { mutateAsync: createCredentialsMeta } = useCredentialsMetaCreate();
  const [selectedFilter, setSelectedFilter] = useState<number>();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [filteredCredentials, setFilteredCredentials] = useState<
    CredentialMetaExtended[]
  >([]);
  const [
    selectedCredential,
    setSelectedCredential,
  ] = useState<CredentialMetaExtended>();

  const withInfoTipData = useMemo(
    () => data?.filter(item => item.info_tip) ?? [],
    [data],
  );

  const withoutInfoTipData = useMemo(
    () => data?.filter(item => !item.info_tip) ?? [],
    [data],
  );

  const credentialOptions: Option<number>[] = useMemo(
    () =>
      withInfoTipData?.map(
        ({ onboarding_document_definition_id, document_name }) => ({
          name: document_name || '',
          value: onboarding_document_definition_id || 0,
        }),
      ) ?? [],
    [withInfoTipData],
  );

  const credentialOptionsForAdding: Option<number>[] = useMemo(
    () =>
      onboardingDocumentDefinitions
        ?.filter?.(
          ({ id }) =>
            !data?.find(
              ({ onboarding_document_definition_id }) =>
                onboarding_document_definition_id === id,
            ),
        )
        ?.map(({ document_name, id }) => ({
          name: document_name || '',
          value: id || 0,
        })) ?? [],
    [data, onboardingDocumentDefinitions],
  );

  useEffect(() => {
    setFilteredCredentials(
      withInfoTipData?.sort((a, b) =>
        (a.document_name?.toUpperCase() ?? '') <
        (b.document_name?.toUpperCase() ?? '')
          ? -1
          : 1,
      ),
    );
  }, [withInfoTipData]);

  const handleSelectedFilter = (value: number) => {
    const filteredValue = withInfoTipData.filter(
      item => item.onboarding_document_definition_id === value,
    );
    setFilteredCredentials(filteredValue);
  };

  const handleSelectedModalFilter = (value: number) => {
    const existingValue = withoutInfoTipData.find(
      item => item.onboarding_document_definition_id === value,
    );

    setSelectedCredential(prevState => ({
      ...prevState,
      ...(existingValue ?? {
        onboarding_document_definition_id: value,
      }),
      info_tip: '',
    }));
  };

  const handleAddOrEdit = useCallback(async () => {
    try {
      if (selectedCredential) {
        setIsAdding(true);
        const {
          disabled_tip,
          info_tip,
          onboarding_document_definition_id,
        }: CredentialMeta = selectedCredential;

        await createCredentialsMeta({
          disabled_tip,
          info_tip,
          onboarding_document_definition_id,
        });
        await refetch();
        setIsAdding(false);
        toast.success('Successfully updated the credential');
      }
    } catch (error) {
      handleError(error, ErrorContext.EditCredentialsMeta);
    } finally {
      setCreateModalVisible(false);
      setEditModalVisible(false);
      setSelectedFilter(undefined);
    }
  }, [createCredentialsMeta, refetch, selectedCredential]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInfo = event.target.value;
    setSelectedCredential(prevState => ({
      ...prevState,
      info_tip: newInfo,
    }));
  };

  return (
    <div className="flex flex-col w-screen pr-32 gap-4">
      {isLoading ? <Loader /> : null}
      {createModalVisible && (
        <Modal
          closeLabel="Cancel"
          onClose={() => setCreateModalVisible(false)}
          SubmitButton={
            <Button
              disabled={
                isAdding ||
                !selectedCredential?.onboarding_document_definition_id ||
                !selectedCredential?.info_tip
              }
              onClick={() => handleAddOrEdit()}
            >
              Save
            </Button>
          }
          title="Add New Credential Details"
        >
          <div className="flex flex-col p-2 gap-4">
            <Label label="Credential:">
              <Select
                options={credentialOptionsForAdding}
                onChange={value => handleSelectedModalFilter(value)}
              />
            </Label>
            <Label label="Info Tip Text" color="text-black">
              <Input
                type="text"
                value={selectedCredential?.info_tip}
                onChange={e => handleInputChange(e)}
              />
            </Label>
          </div>
        </Modal>
      )}
      {editModalVisible && (
        <Modal
          closeLabel="Cancel"
          onClose={() => setEditModalVisible(false)}
          SubmitButton={
            <Button
              disabled={isAdding || !selectedCredential?.info_tip}
              onClick={() => handleAddOrEdit()}
            >
              Save
            </Button>
          }
          title="Edit Credential Details"
        >
          <div className="flex flex-col p-2 gap-4">
            <p className="font-semibold">{selectedCredential?.document_name}</p>
            <Label label="Info Tip Text" color="text-black">
              <Input
                type="text"
                value={selectedCredential?.info_tip}
                onChange={e => handleInputChange(e)}
              />
            </Label>
          </div>
        </Modal>
      )}

      <div className="flex gap-2 py-4 items-center flex-wrap">
        <Label label="Credential:">
          <Select
            options={credentialOptions}
            value={selectedFilter}
            onChange={value => {
              handleSelectedFilter(value);
            }}
          />
        </Label>
        <Button
          className="mt-3 border-none shadow"
          variant="outline"
          onClick={() => {
            setSelectedFilter(-1);
            setFilteredCredentials(withInfoTipData);
          }}
        >
          Reset
        </Button>
      </div>
      <table
        className="table-row overflow-y-auto relative border"
        style={{ minHeight: 500 }}
      >
        <thead>
          <tr>
            <th className="border-b border-r p-2"></th>
            <th className="border-r border-b p-2 w-1/2">Credential</th>
            <th className="border-r border-b p-2 w-1/2">Info Tip</th>
          </tr>
        </thead>
        <tbody>
          {!filteredCredentials?.length && (
            <tr aria-colspan={3}>
              <td>
                <div className="top-1/2 left-1/2 absolute text-gray-200 flex gap-2 items-center text-3xl -ml-40 font-thin">
                  <FaFolder />
                  <span>Nothing to show here...</span>
                </div>
              </td>
            </tr>
          )}
          {filteredCredentials?.map(meta => {
            const { document_name: documentName, info_tip: infoTip } = meta;

            return (
              <tr key={`${documentName}-${infoTip}`} className="h-10">
                <td className="border p-2">
                  <FaPencilAlt
                    className="cursor-pointer"
                    onClick={() => {
                      setEditModalVisible(true);
                      setSelectedCredential(meta);
                    }}
                  />
                </td>
                <td className="border p-2 w-1/3">{documentName}</td>
                <td className="border p-2 w-1/3">{infoTip}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between py-2 border-t-2 border-gray-50">
        <div className="font-semibold text-gray-700">
          Showing {filteredCredentials?.length ?? 0} results
        </div>
        <Button
          className="self-end"
          variant="secondary"
          onClick={() => {
            setCreateModalVisible(true);
            setSelectedCredential(undefined);
          }}
        >
          Add New
        </Button>
      </div>
    </div>
  );
}
