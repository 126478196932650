import { ToastContainer } from 'react-toastify';
import { Content, Header, LeftNav } from 'components';
import { HeaderProps } from 'components/header';

export interface MainLayoutProps {
  headerProps: HeaderProps;
}

export default function MainLayout({ headerProps }: MainLayoutProps) {
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header {...headerProps} />
      <div className="flex">
        <LeftNav />
        <div className="self-stretch p-2 h-screen overflow-auto pb-20">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
          />
          <Content />
        </div>
      </div>
    </div>
  );
}
