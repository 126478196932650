import { FC, useState } from 'react';
import TabHeader from './tab-header';

export interface TabComponents {
  component: () => JSX.Element;
  title: string;
  onlySuperUserCanSee?: boolean;
}

type Props = {
  tabs: TabComponents[];
  initialTab?: number;
};

const Tabs: FC<Props> = ({ tabs, initialTab }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab || 0);
  const SelectedComponent = tabs[selectedTab].component;

  return (
    <div className="flex flex-wrap px-4 py-2">
      <div className="w-full">
        <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
          {tabs.map((item, index) => (
            <TabHeader
              key={`${item.title}${index}`}
              title={item.title}
              index={index}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </ul>
        <SelectedComponent />
      </div>
    </div>
  );
};

export default Tabs;
