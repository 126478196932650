import { FC, ComponentType } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from 'components';
import logoUrl from 'assets/logo.png';

toast.configure();

const withPublicLayout = <P extends object>(
  WrappedComponent: ComponentType<P>,
): FC<P> => ({ ...props }) => {
  return (
    <div className="h-screen bg-gray-100">
      <Header logoUrl={logoUrl} />
      <div className="flex flex-col">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
        />
        <WrappedComponent {...(props as P)} />
      </div>
    </div>
  );
};

export default withPublicLayout;
