import {
  Classifications,
  SuperUsers,
  Tabs,
  Credentials,
  DynamicConfig,
} from 'components';
import { TabComponents } from 'components/tabs';
import { useUserAuth } from 'hooks';
import { APP_CONFIG_KEYS } from '../constants';

const AppSettingsTab = () => (
  <div id="1">
    <DynamicConfig />
  </div>
);
const ClassificationsTab = () => (
  <div id="2">
    <Classifications />
  </div>
);
const TermsAndServicesTab = () => (
  <div id="3">
    <DynamicConfig keys={[APP_CONFIG_KEYS.TAS]} />
  </div>
);
const CredentialsTab = () => (
  <div id="4" className="flex flex-col gap-4 w-screen p-2">
    <Credentials />
  </div>
);
const SuperUsersTab = () => (
  <div id="5" className="flex flex-col gap-4 w-screen p-2">
    <SuperUsers />
  </div>
);

const components: TabComponents[] = [
  { component: AppSettingsTab, title: 'Modules default values' },
  { component: ClassificationsTab, title: 'Classifications' },
  { component: TermsAndServicesTab, title: 'Terms & Services' },
  { component: CredentialsTab, title: 'Credentials' },
  {
    component: SuperUsersTab,
    title: 'User Management',
    onlySuperUserCanSee: true,
  },
];

const Settings = () => {
  const {
    user: { isSuperUser },
  } = useUserAuth();

  const visibleTabs = components.filter(
    item =>
      !item.onlySuperUserCanSee || (item.onlySuperUserCanSee && isSuperUser),
  );

  return <Tabs tabs={visibleTabs} initialTab={0} />;
};
export default Settings;
