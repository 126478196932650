import { useCallback, useState } from 'react';
import { Toggle } from 'components';
import { CustomField } from 'core';
import { useCustomFields, useCredentialCustomFields } from 'hooks';

export interface CustomFieldsProps {
  sourceHook: typeof useCustomFields | typeof useCredentialCustomFields;
  onChange?(customFields: string): void;
  selectedCustomFields?: string;
}

export interface CustomFieldsData {
  data?: CustomField[];
}

export default function CustomFields({
  sourceHook,
  onChange,
  selectedCustomFields,
}: CustomFieldsProps) {
  const [ids, setIds] = useState(
    (selectedCustomFields?.split(',') ?? []).map(Number),
  );

  const { data }: CustomFieldsData = sourceHook();

  const handleChange = useCallback(
    (id: number, checked: boolean) => {
      let newIds = ids;

      if (checked) {
        newIds = [...newIds, id];
      } else {
        newIds = newIds.filter(oldId => oldId !== id);
      }

      setIds(newIds);
      onChange?.(newIds.join(','));
    },
    [ids, onChange],
  );

  if (!data) return null;

  return (
    <>
      {data.map(({ name, field_id }) => {
        if (!field_id) return null;

        return (
          <div key={field_id} className="flex gap-4">
            <div className="w-10 text-gray-400 font-semibold">{field_id}</div>
            <Toggle
              checked={ids.includes(field_id)}
              onChange={checked => handleChange(field_id, checked)}
            />
            <div>{name}</div>
          </div>
        );
      })}
    </>
  );
}
