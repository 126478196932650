import { Reducer, useCallback, useMemo, useReducer, useState } from 'react';
import { Classification } from 'core';
import { useFacilities, useJobTitles, useVendors } from 'hooks';

import { Button, Label, Modal, Select } from './';
import { ModalProps } from './modal';
import { Option } from './select';

interface ReducerState {
  facilityId?: number;
  jobTitleId?: number;
  vendorId?: number;
  facilitiesSearchTerm?: string
  jobTitlesSearchTerm?: string
  vendorsSearchTerm?: string
}

type ReducerAction =
  | { type: 'setFacilityId'; payload: number }
  | { type: 'setJobTitleId'; payload: number }
  | { type: 'setVendorId'; payload: number };

export interface AddClassificationModalProps
  extends Pick<ModalProps, 'onClose'> {
  initialState?: ReducerState;
  onAdd?(classification: Classification, addAnother?: boolean): Promise<void>;
}

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'setFacilityId':
      return { ...state, facilityId: action.payload };
    case 'setJobTitleId':
      return { ...state, jobTitleId: action.payload };
    case 'setVendorId':
      return { ...state, vendorId: action.payload };
    default:
      return state;
  }
};

export default function AddClassificationModal({
  initialState,
  onAdd,
  onClose,
}: AddClassificationModalProps) {
  const [state, dispatch] = useReducer(reducer, initialState ?? {});
  const [addAnother, setAddAnother] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [facilitiesSearchTerm, setFacilitiesSearchTerm] = useState(initialState?.facilitiesSearchTerm);
  const [jobTitlesSearchTerm, setJobTitlesSearchTerm] = useState(initialState?.jobTitlesSearchTerm);
  const [vendorsSearchTerm, setVendorsSearchTerm] = useState(initialState?.vendorsSearchTerm);
  const { data: facilities } = useFacilities(facilitiesSearchTerm);
  const { data: jobTitles } = useJobTitles(jobTitlesSearchTerm);
  const { data: vendors } = useVendors(vendorsSearchTerm);
  const facilityOptions: Option<number>[] = useMemo(
    () => facilities?.map(({ id, name }) => ({ name, value: id })) ?? [],
    [facilities],
  );
  const jobTitleOptions: Option<number>[] = useMemo(
    () => jobTitles?.map(({ id, name }) => ({ name, value: id })) ?? [],
    [jobTitles],
  );
  const vendorOptions: Option<number>[] = useMemo(
    () => [
      { name: 'Self-registered', value: 0 },
      ...(vendors?.map(({ id, name }) => ({ name, value: id })) ?? []),
    ],
    [vendors],
  );
  const canAdd = useMemo(
    () =>
      state.facilityId !== undefined &&
      state.jobTitleId !== undefined &&
      state.vendorId !== undefined,
    [state.facilityId, state.jobTitleId, state.vendorId],
  );

  const handleAddAnother = useCallback(
    e => {
      e.stopPropagation();
      setAddAnother(!addAnother);
    },
    [addAnother],
  );

  const handleAddMapping = useCallback(async () => {
    setIsAdding(true);
    await onAdd?.(
      {
        facility_id: state.facilityId,
        jobtitle_id: state.jobTitleId,
        vendor_id: state.vendorId,
      },
      addAnother,
    );
    setIsAdding(false);
  }, [addAnother, onAdd, state.facilityId, state.jobTitleId, state.vendorId]);

  return (
    <Modal
      closeLabel="Cancel"
      onClose={onClose}
      SubmitButton={
        <Button disabled={isAdding || !canAdd} onClick={handleAddMapping}>
          Add Mapping
        </Button>
      }
      title="Add New Classification Mapping"
    >
      <div
        className="flex gap-2 items-center absolute bottom-8 cursor-pointer"
        onClick={handleAddAnother}
      >
        <input type="checkbox" checked={addAnother} />
        <span>Add Another</span>
      </div>
      <div className="flex flex-col p-2 gap-4">
        <Label label="Classification:">
          <Select
            options={jobTitleOptions}
            value={state.jobTitleId}
            onChange={value =>
              dispatch({ type: 'setJobTitleId', payload: value })
            }
            onSearch={setJobTitlesSearchTerm}
          />
        </Label>
        <Label label="Facility:">
          <Select
            options={facilityOptions}
            value={state.facilityId}
            onChange={value =>
              dispatch({ type: 'setFacilityId', payload: value })
            }
            onSearch={setFacilitiesSearchTerm}
          />
        </Label>
        <Label label="Vendor:">
          <Select
            options={vendorOptions}
            value={state.vendorId}
            onChange={value =>
              dispatch({ type: 'setVendorId', payload: value })
            }
            onSearch={setVendorsSearchTerm}
          />
        </Label>
      </div>
    </Modal>
  );
}
