import { useMutation } from 'react-query';
import { CredentialMeta } from 'core';
import { useApi } from 'providers';

const useCredentialsMetaCreate = () => {
  const api = useApi();

  return useMutation(async (data: CredentialMeta) =>
    api.credential.metadataCreate(data),
  );
};

export default useCredentialsMetaCreate;
