import { Redirect, useHistory, useLocation, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorContext, handleError } from 'core';
import { useLogin, useUserAuth } from 'hooks';
import { Input, Button, Loader, Error } from 'components';
import withPublicLayout from 'layouts/with-public-layout';

import { ROUTES } from '../constants';

import 'index.css';

type LoginData = {
  email: string;
  password: string;
};

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { user, authorize } = useUserAuth();
  const { isAuthenticated } = user;

  const { mutateAsync } = useLogin();

  const history = useHistory();
  const { search } = useLocation();
  const redirect = () => history.push(ROUTES.DHP);

  const onSubmit = async (values: LoginData) => {
    const { email, password } = values;
    try {
      const response = await mutateAsync({ email, password });
      if (response.ok) {
        const token = response.data.idToken.jwtToken;
        if (token) {
          await authorize(token);
          redirect();
        }
      }
    } catch (error) {
      handleError(error, ErrorContext.Authorization);
    }
  };

  if (isAuthenticated) {
    const params = new URLSearchParams(search);

    return <Redirect to={params.get('pathname') ?? ROUTES.DHP} />;
  }

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="flex justify-center">
      <form className="w-72 m-5 py-5 flex flex-col gap-5 ">
        <p className="font-bold text-primary text-xl text-center">Welcome</p>
        <Input
          {...register('email', { required: true })}
          id="email"
          className="rounded shadow p-2"
          placeholder="Email"
          autoComplete="email"
        />
        {errors.email && <Error message={'Email is required'} />}
        <Input
          {...register('password', { required: true, minLength: 6 })}
          id="password"
          className="rounded shadow p-2"
          placeholder="Password"
          type="password"
          autoComplete="current-password"
        />
        {errors.password && (
          <Error message={'Password of at least 6 characters is required'} />
        )}
        <div className="flex flex-row justify-between items-baseline">
          <Link
            to={ROUTES.FORGOT_PASSWORD}
            className="text-secondary font-semibold cursor-pointer"
          >
            Forgot password?
          </Link>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};
export default withPublicLayout(Login);
