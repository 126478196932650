const Loader = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="border-solid animate-spin rounded-full border-blue-600 border-8 h-64 w-64"
      ></div>
    </div>
    Loading
  </div>
);

export default Loader;
