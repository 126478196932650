import { useQuery } from 'react-query';
import { ConfigAppEnum, AppKeyValueTypeLabel } from 'core';
import { useApi } from 'providers';

const useConfigs = (app?: ConfigAppEnum, keys?: string[]) => {
  const api = useApi();

  return useQuery<AppKeyValueTypeLabel[]>(
    [`configs/${app}${keys}`, api],
    async () => {
      const { data } = await api.configs.configsList({
        app,
        keys: keys?.join(','),
      });

      return data;
    },
    {
      cacheTime: 1000 * 60 * 30,
    },
  );
};

export default useConfigs;
