import { useLocation, Redirect, Route, RouteProps } from 'react-router-dom';

import { useUserAuth } from 'hooks';
import { MainLayout } from 'layouts';
import { ROUTES } from '../constants';

import logoUrl from 'assets/logo.png';

const ProtectedRoute = ({ ...props }: RouteProps) => {
  const { user: { isAuthenticated, claims } } = useUserAuth();
  const { pathname } = useLocation();

  if (isAuthenticated && claims) {
    return (
      <Route {...props}>
        <MainLayout
          headerProps={{
            logoUrl,
            user: {
              fullName: `${claims.name} ${claims.family_name}`,
              email: claims.email,
            },
          }}
        />
      </Route>
    );
  }

  return <Redirect to={`${ROUTES.LOGIN}?pathname=${pathname}`} />;
};

export default ProtectedRoute;
