import { useQuery } from 'react-query';
import { IdName } from 'core';
import { useApi } from 'providers';

const useFacilities = (name?: string) => {
  const api = useApi();

  return useQuery<IdName[]>(
    ['facilities', api, name],
    async () => {
      if (!name) return []

      const { data } = await api.classification.facilitiesList({ name });

      return data;
    },
    { staleTime: Number.POSITIVE_INFINITY },
  );
};

export default useFacilities;
