/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Error
 */
export interface Error {
  /** @format int64 */
  code?: number;
  message?: string;
}

/**
 * id name pair
 */
export interface IdName {
  id: number;
  name: string;
}

/**
 * id name value
 */
export interface IdNameValue {
  id: number;
  name: string;
  value: string;
}

export enum RepoTypeEnum {
  APPROVER = 'APPROVER',
  DELEGATE = 'DELEGATE',
  DHP = 'DHP',
}

export enum ConfigAppEnum {
  Dhp = 'dhp',
  Delegate = 'delegate',
  Facility = 'facility',
}

/**
 * users/identify repository status
 */
export enum IdentifyStatusEnum {
  Ok = 'ok',
  NotFound = 'not-found',
  Fail = 'fail',
  Timeout = 'timeout',
  NotConfirmed = 'not-confirmed',
  Error = 'error',
}

/**
 * users/clean repository status
 */
export enum CleanStatusEnum {
  Ok = 'ok',
  NotFound = 'not-found',
  Fail = 'fail',
  Timeout = 'timeout',
  ExternalIdMissmatch = 'external-id-missmatch',
  Error = 'error',
}

/**
 * users/clean repository status
 */
export enum SwapStatusEnum {
  Ok = 'ok',
  NotFound = 'not-found',
  Fail = 'fail',
  Timeout = 'timeout',
  ExistingUsername = 'existing-username',
  ExternalIdMissmatch = 'external-id-missmatch',
  Unauthorized = 'unauthorized',
  Error = 'error',
}

/**
 * users/clean repository status
 */
export enum FixStatusEnum {
  Ok = 'ok',
  NotFound = 'not-found',
  Fail = 'fail',
  Timeout = 'timeout',
  Unauthorized = 'unauthorized',
  Error = 'error',
}

/**
 * email password pair
 */
export interface EmailPassword {
  /**
   * username
   * @format email
   */
  email: string;

  /** password */
  password: string;
}

/**
 * a set of cognito tokens
 */
export interface CognitoTokens {
  idToken: { jwtToken: string };
  accessToken: { jwtToken: string };
  refreshToken: { token: string };
}

/**
 * information required to reset password
 */
export interface ResetPasswordRequest {
  /**
   * admin email/username
   * @format email
   */
  email: string;

  /** new password */
  new_password: string;

  /** cognito generated verification code. */
  verification_code: string;
}

export interface Email {
  /**
   * email
   * @format email
   */
  email: string;
}

export interface SuperUser {
  /**
   * username
   * @format email
   */
  email: string;

  /** first name */
  first: string;

  /** last name */
  last: string;

  /** middle name */
  middle?: string;

  /**
   * account assigned identity number
   * @format int64
   */
  external_id?: number;

  /** indicator if user will be able to administrate other users */
  is_superuser?: boolean;

  /**
   * account assigned identity number
   * @format uuid
   */
  entity_uuid?: string | null;
}

export type Account = SuperUser & { password?: string };

export type SuperUserInfo = SuperUser & {
  created_at?: string;
  enabled?: boolean;
};

export interface UserIdentityReq {
  /**
   * @format email
   * @example eric@gmail.com
   */
  email: string;
  type?: RepoTypeEnum;
}

export type UserIdentityWithPasswordReq = UserIdentityReq & {
  password: string;
};

export interface UserIdentityResponse {
  /**
   * @format email
   * @example eric@gmail.com
   */
  email: string;
  type?: RepoTypeEnum;

  /** @example eric(at)gmail.com */
  username?: string;

  /**
   * @format int64
   * @example 101564
   */
  external_id?: number;

  /** @example *** */
  password?: string;

  /**
   * erecruit repo identify status
   * @example ok
   */
  erecruit?: IdentifyStatusEnum;

  /**
   * cognito repo identify status
   * @example ok
   */
  cognito?: IdentifyStatusEnum;

  /**
   * database record identify status
   * @example ok
   */
  record?: IdentifyStatusEnum;

  /**
   * in case of error this should hold error info
   * @example null
   */
  error?: string;
}

export interface UserCleanReq {
  /**
   * @format email
   * @example eric@gmail.com
   */
  email: string;
  type?: RepoTypeEnum;
}

export interface UserCleanResponse {
  /**
   * @format email
   * @example eric@gmail.com
   */
  email: string;
  type?: RepoTypeEnum;

  /** @example eric(at)gmail.com */
  username?: string;

  /**
   * @format int64
   * @example 101564
   */
  external_id?: number;

  /**
   * cognito repo identify status
   * @example ok
   */
  cognito?: CleanStatusEnum;

  /**
   * database record identify status
   * @example ok
   */
  record?: CleanStatusEnum;

  /**
   * clean operation status (all in common)
   * @example ok
   */
  status?: CleanStatusEnum;

  /**
   * in case of error this should hold error info
   * @example null
   */
  error?: string;
}

export interface UserSwapReq {
  /**
   * current email/username
   * @format email
   * @example eric@gmail.com
   */
  email: string;

  /**
   * new email/username
   * @format email
   * @example eric@hotmail.com
   */
  new_email: string;
  type?: RepoTypeEnum;
}

export interface UserSwapResponse {
  /**
   * current email/username
   * @format email
   * @example eric@gmail.com
   */
  email: string;

  /**
   * new email/username
   * @format email
   * @example eric@hotmail.com
   */
  new_email?: string;
  type?: RepoTypeEnum;

  /**
   * cognito repo identify status
   * @example ok
   */
  cognito?: SwapStatusEnum;

  /**
   * database record identify status
   * @example ok
   */
  record?: SwapStatusEnum;

  /**
   * erecruit repo identify status
   * @example ok
   */
  erecruit?: SwapStatusEnum;

  /**
   * clean operation status (all in common)
   * @example ok
   */
  status?: SwapStatusEnum;

  /**
   * in case of error this should hold error info
   * @example null
   */
  error?: string;
}

export interface UserFixReq {
  /**
   * current email/username
   * @format email
   * @example eric@gmail.com
   */
  email: string;

  /** if sent, this will be used to identify users through password. if not sent record stored password will be used */
  password?: string;

  /** if sent, this will become new password */
  new_password?: string;
  type?: RepoTypeEnum;
}

export interface UserFixResponse {
  /**
   * current email/username
   * @format email
   * @example eric@gmail.com
   */
  email: string;
  type?: RepoTypeEnum;

  /**
   * cognito repo identify status
   * @example ok
   */
  cognito?: FixStatusEnum;

  /**
   * database record identify status
   * @example ok
   */
  record?: FixStatusEnum;

  /**
   * erecruit repo identify status
   * @example ok
   */
  erecruit?: FixStatusEnum;

  /**
   * in case of error this should hold error info
   * @example null
   */
  error?: string;
}

export interface KeyValue {
  /** key */
  key?: string;

  /** value */
  value?: string;
}

export interface KeyType {
  /** key */
  key?: string;

  /** key type */
  type?: string;
}

export type AppKeyValue = KeyValue & {
  app?: ConfigAppEnum;
  key?: string;
  value?: string;
};

export type AppKeyValueType = AppKeyValue & { type?: string };

export type AppKeyValueTypeLabel = AppKeyValueType & {
  label?: string;
  tooltip?: string;
};

export interface SaveAppKeyValue {
  /**
   * application name
   * @example delegate
   */
  app?: ConfigAppEnum;
  records?: AppKeyValue[];
}

export interface Classification {
  /** facility id */
  facility_id?: number;

  /** folder group /job title id */
  jobtitle_id?: number;

  /** vendor / agency id */
  vendor_id?: number;
}

export type ClassificationGrid = Classification & {
  facility?: string;
  jobtitle?: string;
  vendor?: string;
};

export interface CustomField {
  /** field id */
  field_id?: number;

  /** field name */
  name?: string;

  /** default value */
  default_value?: string;
}

export interface Base64File {
  /** file name */
  name?: string;

  /** base 64 file content */
  base64Content?: string;
}

export interface S3FileInfo {
  /** file name */
  name?: string;

  /** file bucket path */
  full_path?: string;

  /** file size */
  size?: number;
}

export interface OnboardingDocumentDefinitionShort {
  /** document definition id */
  id?: number;

  /** document name */
  document_name?: string;

  /** is definition active */
  is_active?: boolean;
}

export interface CredentialMeta {
  /** document definition id */
  onboarding_document_definition_id?: number;

  /** info tip */
  info_tip?: string;

  /** disabled tip */
  disabled_tip?: string;
}

export type CredentialMetaExtended = CredentialMeta & {
  document_name?: string;
  is_active?: boolean;
};

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string =
    'https://ehsxc2kzrk.execute-api.us-west-2.amazonaws.com/edhp/vendor/admin';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private addQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`;
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return `${value.map(this.addQueryParam).join('&')}`;
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      key => 'undefined' !== typeof query[key],
    );
    return keys
      .map(key =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async response => {
      const r = response as HttpResponse<T, E>;
      r.data = (null as unknown) as T;
      r.error = (null as unknown) as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch(e => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title EDHP Admin API
 * @version 1.0.0
 * @baseUrl https://ehsxc2kzrk.execute-api.us-west-2.amazonaws.com/edhp/vendor/admin
 * @contact Ante Mihalj <amihalj@erecruit.com>
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  account = {
    /**
     * @description Admin user authentication
     *
     * @name AuthenticateCreate
     * @summary Admin user authentication
     * @request POST:/account/authenticate
     */
    authenticateCreate: (data: EmailPassword, params: RequestParams = {}) =>
      this.request<CognitoTokens, Error>({
        path: `/account/authenticate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description In case admin user cannot remember own password, one can use this route to get a verification code in email and perform password-reset
     *
     * @name ForgotPasswordCreate
     * @summary Get password-reset verification code
     * @request POST:/account/forgot-password
     */
    forgotPasswordCreate: (
      data: { email: string },
      params: RequestParams = {},
    ) =>
      this.request<{ status: string }, Error>({
        path: `/account/forgot-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Use verification code provided in email by forgot-password action and set new password
     *
     * @name ResetPasswordCreate
     * @summary request to reset own password
     * @request POST:/account/reset-password
     */
    resetPasswordCreate: (
      data: ResetPasswordRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, Error>({
        path: `/account/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  superUsers = {
    /**
     * @description Action to filter list of superusers
     *
     * @name SuperUsersList
     * @summary Filter a list of admin users
     * @request GET:/super-users
     * @secure
     */
    superUsersList: (params: RequestParams = {}) =>
      this.request<SuperUserInfo[], Error>({
        path: `/super-users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Action to create new admin user
     *
     * @name SuperUsersCreate
     * @summary Create new admin user
     * @request POST:/super-users
     * @secure
     */
    superUsersCreate: (data: Account, params: RequestParams = {}) =>
      this.request<Account, Error>({
        path: `/super-users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Action to enable admin user
     *
     * @name EnableCreate
     * @summary Enable admin user
     * @request POST:/super-users/enable
     * @secure
     */
    enableCreate: (data: Email, params: RequestParams = {}) =>
      this.request<SuperUserInfo, Error>({
        path: `/super-users/enable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Action to disable admin user
     *
     * @name DisableCreate
     * @summary Disable admin user
     * @request POST:/super-users/disable
     * @secure
     */
    disableCreate: (data: Email, params: RequestParams = {}) =>
      this.request<SuperUserInfo, Error>({
        path: `/super-users/disable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  users = {
    /**
     * @description This action will take a list of user emails, perform identification against erecruit, cognito and db and return overal result info. In case all of - erecruit - ok - user can identify with username and password - fail - user cannot identify with username and password - cognito - ok - user can identify with username and password in cognito - fail - user cannot identify with username and password - not-found - no such cognito user - record - ok - user with record and password found - not-found - user with record and password not found
     *
     * @name IdentifyCreate
     * @summary Action to identify user against all 3 systems
     * @request POST:/users/identify
     * @secure
     */
    identifyCreate: (data: UserIdentityReq[], params: RequestParams = {}) =>
      this.request<UserIdentityResponse[], Error>({
        path: `/users/identify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action will take a list of user email-password pairs, perform identification against erecruit, cognito and db and return overal result info. In case all of - erecruit - ok - user can identify with username and password - fail - user cannot identify with username and password - cognito - ok - user can identify with username and password in cognito - fail - user cannot identify with username and password - not-found - no such cognito user - record - ok - user with record and password found - not-found - user with record and password not found
     *
     * @name IdentifyWithPasswordCreate
     * @summary Action to identify user against all 3 systems
     * @request POST:/users/identify-with-password
     * @secure
     */
    identifyWithPasswordCreate: (
      data: UserIdentityWithPasswordReq[],
      params: RequestParams = {},
    ) =>
      this.request<UserIdentityResponse[], Error>({
        path: `/users/identify-with-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action will take a list of user emails, try to perform cleanup or return status on each system. In case all of - cognito - ok - user can identify with username and password in cognito - fail - user cannot identify with username and password - not-found - no such cognito user - record - ok - user with record and password found - not-found - user with record and password not found
     *
     * @name CleanCreate
     * @summary Action to clean aws user against cognito and database repo
     * @request POST:/users/clean
     * @secure
     */
    cleanCreate: (data: UserCleanReq[], params: RequestParams = {}) =>
      this.request<UserCleanResponse[], Error>({
        path: `/users/clean`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action will take a list of user old-new email, perform username change in all 3 systems. In case all of - erecruit - ok - username changed in erecruit - fail - failed to change due to some reason - cognito - ok - username changed in cognito - fail - failed to change due to some reason - not-found - no such cognito user - record - ok - username changed in db repo - fail - failed to change due to some reason - not-found - user with record and password not found
     *
     * @name SwapUsernameCreate
     * @summary Action to change user's username
     * @request POST:/users/swap-username
     * @secure
     */
    swapUsernameCreate: (data: UserSwapReq[], params: RequestParams = {}) =>
      this.request<UserSwapResponse[], Error>({
        path: `/users/swap-username`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action will take a list of user old-new email, perform username change in all 3 systems. In case all of - erecruit - ok - username changed in erecruit - fail - failed to change due to some reason - cognito - ok - username changed in cognito - fail - failed to change due to some reason - not-found - no such cognito user - record - ok - username changed in db repo - fail - failed to change due to some reason - not-found - user with record and password not found
     *
     * @name PostUsers
     * @summary Action to try to fix user account and synced credentials
     * @request POST:/users/fix
     * @secure
     */
    postUsers: (data: UserFixReq, params: RequestParams = {}) =>
      this.request<UserFixResponse, Error>({
        path: `/users/fix`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  configs = {
    /**
     * @description This action is used to fetch a list of configuration values that will be used in VPRO modules like: - 'VPRO' - 'DELEGATE' - 'APPROVER' - 'KIOSK'
     *
     * @name ConfigsList
     * @summary Get a list of app configurations
     * @request GET:/configs
     * @secure
     */
    configsList: (
      query?: { app?: string; keys?: string },
      params: RequestParams = {},
    ) =>
      this.request<AppKeyValueType[], Error>({
        path: `/configs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action is used to set a list of configuration values that will be used in VPRO modules Done
     *
     * @name ConfigsCreate
     * @summary Set configuration key, value
     * @request POST:/configs
     * @secure
     */
    configsCreate: (data: SaveAppKeyValue, params: RequestParams = {}) =>
      this.request<AppKeyValue[], Error>({
        path: `/configs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action is used to fetch a list of configuration values that will be used in VPRO modules like: - 'VPRO' - 'DELEGATE' - 'APPROVER' - 'KIOSK'
     *
     * @name KeysList
     * @summary Get a list of app configuration keys
     * @request GET:/configs/keys
     * @secure
     */
    keysList: (query?: { app?: string }, params: RequestParams = {}) =>
      this.request<KeyType[], Error>({
        path: `/configs/keys`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  classification = {
    /**
     * @description This action is used to fetch a list of classification. Note since this is not paginated response,  in case there is no filter no response should be returned (Insuficient Filter error should be returned)
     *
     * @name ClassificationList
     * @summary Get a list of app facility vendor classifications
     * @request GET:/classification
     * @secure
     */
    classificationList: (
      query?: {
        facility_ids?: string;
        jobtitle_ids?: string;
        vendor_ids?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClassificationGrid[], Error>({
        path: `/classification`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Inserts classification mapping.
     *
     * @name ClassificationCreate
     * @summary Set classification
     * @request POST:/classification
     * @secure
     */
    classificationCreate: (data: Classification, params: RequestParams = {}) =>
      this.request<Classification[], Error>({
        path: `/classification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes classification mapping.
     *
     * @name ClassificationDelete
     * @summary Delete classification
     * @request DELETE:/classification
     * @secure
     */
    classificationDelete: (data: Classification, params: RequestParams = {}) =>
      this.request<Classification[], Error>({
        path: `/classification`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action is used to fetch a list of facilities used for classification.
     *
     * @name FacilitiesList
     * @summary Get a list of facilities
     * @request GET:/classification/facilities
     * @secure
     */
    facilitiesList: (query?: { name?: string }, params: RequestParams = {}) =>
      this.request<IdName[], Error>({
        path: `/classification/facilities`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action is used to fetch a list of job titles used for classification.
     *
     * @name JobTitlesList
     * @summary Get a list of job titles
     * @request GET:/classification/job-titles
     * @secure
     */
    jobTitlesList: (query?: { name?: string }, params: RequestParams = {}) =>
      this.request<IdName[], Error>({
        path: `/classification/job-titles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This action is used to fetch a list of vendors used for classification.
     *
     * @name VendorsList
     * @summary Get a list of vendors
     * @request GET:/classification/vendors
     * @secure
     */
    vendorsList: (query?: { name?: string }, params: RequestParams = {}) =>
      this.request<IdName[], Error>({
        path: `/classification/vendors`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  candidateCustomFields = {
    /**
     * @description Get a list of custom fields that can be assigned to a candidate.
     *
     * @name CandidateCustomFieldsList
     * @summary Get a list of candidate custom fields (definitions)
     * @request GET:/candidate-custom-fields
     * @secure
     */
    candidateCustomFieldsList: (params: RequestParams = {}) =>
      this.request<CustomField[], Error>({
        path: `/candidate-custom-fields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  credential = {
    /**
     * @description Get a list of  onboarding document definitions
     *
     * @name DefinitionsList
     * @summary Get a list of onboarding document definitions
     * @request GET:/credential/definitions
     * @secure
     */
    definitionsList: (params: RequestParams = {}) =>
      this.request<OnboardingDocumentDefinitionShort[], Error>({
        path: `/credential/definitions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a list of credential metadatas
     *
     * @name MetadataList
     * @summary Get a list of credential metadatas
     * @request GET:/credential/metadata
     * @secure
     */
    metadataList: (params: RequestParams = {}) =>
      this.request<CredentialMetaExtended[], Error>({
        path: `/credential/metadata`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets credential metadata for given onboarding document definition
     *
     * @name MetadataCreate
     * @summary save credential metadata
     * @request POST:/credential/metadata
     * @secure
     */
    metadataCreate: (data: CredentialMeta, params: RequestParams = {}) =>
      this.request<CredentialMetaExtended, Error>({
        path: `/credential/metadata`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a list of custom fields that can be assigned to a object requirement/credential.
     *
     * @name CustomFieldsList
     * @summary Get a list of object reqest custom fields (definitions)
     * @request GET:/credential/custom-fields
     * @secure
     */
    customFieldsList: (params: RequestParams = {}) =>
      this.request<CustomField[], Error>({
        path: `/credential/custom-fields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  images = {
    /**
     * @description Sets base64 encoded image into s3 bucket.
     *
     * @name ImagesCreate
     * @summary Set binary image into s3 bucket (used for logos)
     * @request POST:/images
     * @secure
     */
    imagesCreate: (data: Base64File, params: RequestParams = {}) =>
      this.request<S3FileInfo, Error>({
        path: `/images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
