import { useQuery } from 'react-query';
import { OnboardingDocumentDefinitionShort } from 'core';
import { useApi } from 'providers';

const useCredentialsMeta = () => {
  const api = useApi();

  return useQuery<OnboardingDocumentDefinitionShort[]>(
    ['credentialsDefinitions', api],
    async () => {
      const { data } = await api.credential.definitionsList();

      return data;
    },
    {
      cacheTime: 1000 * 60 * 30,
    },
  );
};

export default useCredentialsMeta;
