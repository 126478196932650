import { useCallback, useState } from 'react';
import { FaImage } from 'react-icons/fa';
import { Button } from 'components';
import { ConfigAppEnum, ErrorContext, handleError } from 'core';
import { useConfigsMutation, useUploadImage } from 'hooks';
import { toast } from 'react-toastify';

import Input from './input';

export interface ImageProps {
  app?: ConfigAppEnum;
  imageUrl?: string;
}

export default function Image({ app, imageUrl: initialImageUrl }: ImageProps) {
  const [imageUrl, setImageUrl] = useState(initialImageUrl);
  const { mutateAsync: uploadImageAsync } = useUploadImage();
  const { mutateAsync: postConfig } = useConfigsMutation();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target: { files } }) => {
      const file = files?.[0];

      if (!file) return;

      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Content = reader.result as string;

        try {
          const s3Info = await uploadImageAsync({
            name: file.name,
            base64Content,
          });
          const newImageUrl = s3Info?.data?.full_path;

          await postConfig({
            app,
            records: [{ app, key: 'LOGO_FILE', value: newImageUrl }],
          });
          toast.success('Image uploaded successfully');
          setImageUrl(newImageUrl);
        } catch (error) {
          handleError(error, ErrorContext.ImageUpload);
        }
      };
    },
    [app, postConfig, uploadImageAsync],
  );

  return (
    <div className="flex flex-col gap-2 w-80">
      <div className="h-40 relative flex flex-col items-center justify-center rounded bg-gray-300 text-white text-5xl overflow-hidden">
        {imageUrl ? (
          <img
            className="object-contain w-full h-full z-10"
            src={imageUrl}
            alt=""
            onError={() => setImageUrl(undefined)}
          />
        ) : (
          <FaImage className="absolute z-0" />
        )}
      </div>
      <div className="flex gap-2 items-stretch">
        <Input className="flex-1" disabled value={imageUrl} />
        <div className="relative h-10 overflow-hidden hover:opacity-70">
          <Button>Browse</Button>
          <input
            className="opacity-0 h-full cursor-pointer absolute left-0"
            onChange={handleChange}
            type="file"
            accept="image/*"
          />
        </div>
      </div>
    </div>
  );
}
